import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React from 'react'
import {useMobileFontOverrides} from '../../../../../../commons/hooks/use-mobile-font-overrides'
import sc from '../../classes.scss'
import {LinkToPage} from '../../link-to-page'
import s from './title.scss'
import {TitleProps} from '.'

export const Title = ({event, onImage, isMobile}: TitleProps) => {
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  return (
    <div
      className={classNames(s.root, sc.textNoMargin, sc.preWrap, {
        [s.onImage]: onImage,
        [s.mobile]: isMobile && mobileFontOverridesEnabled,
      })}
      data-hook={DH.eventTitle}
    >
      <LinkToPage event={event} allowExternal>
        {event.title}
      </LinkToPage>
    </div>
  )
}

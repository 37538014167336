import {getCompactDateAndLocationMargin, isCompactDateAndLocationVisible} from '../../selectors/single-settings'
import {AppProps} from '../app/interfaces'
import {renderIfVisible} from '../hoc/render-if-visible'
import {withMargin} from '../hoc/with-margin'
import {SingleEventShortDateLocation as SingleEventShortDateLocationPresentation} from './short-date-location'

export interface ShortDateLocationProps {
  event: wix.events.Event
  alignment: number
  showDate: boolean
  showLocation: boolean
  onImage?: boolean
  mobile?: boolean
}

const getMargin = ({state}: AppProps) => getCompactDateAndLocationMargin(state)

export const SingleEventShortDateLocation = renderIfVisible<ShortDateLocationProps>(
  withMargin(SingleEventShortDateLocationPresentation, getMargin),
  isCompactDateAndLocationVisible,
)

import {WIDGET_LAYOUT} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React from 'react'
import {getLayoutClassName} from '../../../selectors/classes'
import {EventImage} from '../../event-image'
import s from './image.scss'
import {ImageProps} from '.'

export const Image = ({event, layout, gridPosition, isMobile, size}: ImageProps) => {
  const getContainerStyle = (): React.CSSProperties => {
    if (isMobile) {
      return {
        width: '100%',
        minHeight: 180,
        height: layout === WIDGET_LAYOUT.BACKGROUND ? '100%' : `${size}%`,
        ...(layout === WIDGET_LAYOUT.BACKGROUND ? {} : {position: 'relative'}),
      }
    }

    switch (layout) {
      case WIDGET_LAYOUT.FULL:
      default:
        return {
          width: `${size}%`,
          height: 'auto',
          position: 'relative',
        }
      case WIDGET_LAYOUT.VERTICAL:
        return {
          width: '100%',
          height: `${size}%`,
          position: 'relative',
        }
      case WIDGET_LAYOUT.BACKGROUND:
        return {
          width: '100%',
          height: '100%',
        }
    }
  }

  return (
    <>
      {layout === WIDGET_LAYOUT.BACKGROUND && <div className={s.overlay} data-hook="ev-image-background" />}
      <div
        className={classNames(s[getLayoutClassName(layout)], {[s.mobile]: isMobile})}
        style={getContainerStyle()}
        id="ev-image"
        data-hook={DH.singleImage}
      >
        <EventImage
          event={event}
          opacityFallback={layout === WIDGET_LAYOUT.BACKGROUND}
          noBackground={layout !== WIDGET_LAYOUT.BACKGROUND}
          position={gridPosition}
        />
      </div>
    </>
  )
}

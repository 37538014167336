import {getTitleMargin, isTitleVisible} from '../../../selectors/single-settings'
import {AppProps} from '../../app/interfaces'
import {renderIfVisible} from '../../hoc/render-if-visible'
import {withMargin} from '../../hoc/with-margin'
import {Title as TitlePresentation} from './title'

interface TitleOwnProps {
  event: wix.events.Event
  onImage: boolean
  isMobile: boolean
}

export interface TitleProps extends TitleOwnProps {}

const getMargin = ({state}: AppProps) => getTitleMargin(state)

export const Title = renderIfVisible(withMargin(TitlePresentation, getMargin), isTitleVisible)

import classNames from 'classnames'
import React from 'react'
import {useMobileFontOverrides} from '../../../../../../commons/hooks/use-mobile-font-overrides'
import sc from '../../classes.scss'
import s from './description.scss'
import {DescriptionProps} from '.'

export const Description = ({event, onImage, isMobile}: DescriptionProps) => {
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  return (
    <div
      className={classNames(s.root, sc.textNoMargin, sc.preWrap, {
        [s.onImage]: onImage,
        [s.mobile]: isMobile && mobileFontOverridesEnabled,
      })}
      data-hook="ev-description"
    >
      {event.description}
    </div>
  )
}

import {ISettingsParam} from '@wix/tpa-settings'
import {
  ExperimentNames,
  isEventRsvpButtonVisible,
  isRegistrationClosed,
  isWaitlistEnabled,
  isWithoutRegistration,
} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useMobileFontOverrides} from '../../../../../../commons/hooks/use-mobile-font-overrides'
import {useSettings} from '../../../../hooks/use-settings'
import settingsParams from '../../../../settingsParams'
import sc from '../../classes.scss'
import {LinkToPage} from '../../link-to-page'
import s from './rsvp-button.scss'
import {RsvpButtonProps} from '.'

export const RsvpButton = ({
  event,
  width,
  height,
  onImage,
  navigateToPage,
  isMobile,
  responsiveButton,
  hasUrl,
  rsvpLabelVisible,
  eventRestricted,
}: RsvpButtonProps) => {
  const {experiments} = useExperiments()
  const singleEventNoRegistrationTextSettingEnabled = experiments.enabled(
    ExperimentNames.SingleEventNoRegistrationTextSetting,
  )
  const {get} = useSettings()
  const text = getText(event, get, singleEventNoRegistrationTextSettingEnabled)
  const title = rsvpLabelVisible ? get(settingsParams.RSVPTitle) : null
  const hidden = singleEventNoRegistrationTextSettingEnabled
    ? !isEventRsvpButtonVisible(event)
    : isWithoutRegistration(event)
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()

  const style = {
    minWidth: responsiveButton ? '100%' : width,
    minHeight: height,
  }

  const onClick = (e: React.MouseEvent) => {
    if (eventRestricted) {
      e.stopPropagation()
    }
    navigateToPage()
  }

  const buttonClassNames = classNames(s.button, sc.textLineHeight, {
    [s.onImageButton]: onImage,
    [s.link]: hasUrl,
  })

  return (
    <div
      id={DH.rsvpButton}
      className={classNames(s.container, {
        [s.mobile]: isMobile,
        [s.responsive]: responsiveButton,
        [s.hidden]: hidden,
        [s.mobileFontSize]: isMobile && mobileFontOverridesEnabled,
      })}
    >
      {title && (
        <div className={classNames(s.title, {[s.onImageTitle]: onImage})} data-hook="ev-rsvp-button-title">
          {title}
        </div>
      )}
      {hasUrl && !hidden ? (
        <LinkToPage event={event} dataHook="ev-rsvp-button" className={buttonClassNames} style={style} allowExternal>
          {text}
        </LinkToPage>
      ) : (
        <button style={style} data-hook="ev-rsvp-button" className={buttonClassNames} onClick={onClick}>
          {text}
        </button>
      )}
    </div>
  )
}

const getText = (
  event: wix.events.Event,
  get: (param: ISettingsParam) => any,
  singleEventNoRegistrationTextSettingEnabled: boolean,
) => {
  const noRegistration = isWithoutRegistration(event)
  const registrationClosed = isRegistrationClosed(event)
  const waitlist = isWaitlistEnabled(event)

  if (noRegistration && singleEventNoRegistrationTextSettingEnabled) {
    return get(settingsParams.noRegistrationBtnText)
  }
  if (registrationClosed && !waitlist) {
    return get(settingsParams.registrationClosedButtonText)
  }
  return get(settingsParams.oneButton)
}

import React from 'react'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'

export interface Margins {
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
}

interface WithMarginStateProps {
  margins: Margins
}

interface WithMarginOwnProps {
  hocClassName?: string
}

export function withMargin<ComponentOwnProps>(
  Component: React.ComponentClass | React.SFC<ComponentOwnProps>,
  marginGetterFunc: Function,
) {
  const MarginWrapper = ({margins, hocClassName, ...rest}: any) => (
    <div className={hocClassName} style={{...margins}}>
      <Component {...rest} />
    </div>
  )

  const mapState = (props: AppProps): WithMarginStateProps => {
    const margins = marginGetterFunc(props)

    if (typeof margins === 'number') {
      return {margins: {marginBottom: margins}}
    }

    return {margins}
  }

  return connect<ComponentOwnProps & WithMarginOwnProps, WithMarginStateProps>(mapState)(MarginWrapper)
}

import classNames from 'classnames'
import React from 'react'
import {useMobileFontOverrides} from '../../../../../../commons/hooks/use-mobile-font-overrides'
import {Divider} from '../divider'
import s from './date-location.scss'
import {Date, Location} from './presentation'
import {DateLocationProps} from '.'

export const DateLocation = ({
  event,
  dateVisible,
  locationVisible,
  dividerVisible,
  verticalDividerWidth,
  verticalDividerHeight,
  horizontalDividerWidth,
  horizontalDividerHeight,
  horizontal,
  alignmentClassName,
  isMobile,
}: DateLocationProps) => {
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  return (
    <div
      className={classNames(s[alignmentClassName], {
        [s.noDate]: !dateVisible,
        [s.noLocation]: !locationVisible,
        [s.noDivider]: !dividerVisible,
        [s.horizontal]: horizontal,
        [s.mobile]: isMobile,
        [s.mobileFontSize]: isMobile && mobileFontOverridesEnabled,
      })}
      data-hook="ev-date-location"
    >
      <Date event={event} hocClassName={s.date} dataHook="date" />
      {dividerVisible && (
        <div className={s.verticalDivider}>
          <Divider width={verticalDividerWidth} height={verticalDividerHeight} />
        </div>
      )}
      {dividerVisible && (
        <div className={s.horizontalDivider}>
          <Divider width={horizontalDividerWidth} height={horizontalDividerHeight} />
        </div>
      )}
      <Location event={event} dataHook="location" hocClassName={s.location} />
    </div>
  )
}

import {isMobile} from '../../../../../../commons/selectors/environment'
import {getAlignmentClassName} from '../../../selectors/classes'
import {getComponentConfig} from '../../../selectors/settings'
import {
  getAlignment,
  getDateAndLocationMarginBottom,
  getHorizontalDividerHeight,
  getHorizontalDividerWidth,
  getVerticalDividerHeight,
  getVerticalDividerWidth,
  isDateAndLocationVisible,
  isDateVisible,
  isHorizontalDateAndLocation,
  isLocationVisible,
  isVerticalDividersVisible,
} from '../../../selectors/single-settings'
import {AppProps} from '../../app/interfaces'
import {renderIfVisible} from '../../hoc/render-if-visible'
import {withMargin} from '../../hoc/with-margin'
import {connect} from '../../runtime-context/connect'
import {DateLocation as DateLocationPresentation} from './date-location'

interface DateLocationOwnProps {
  event: wix.events.Event
}

interface DateLocationStateProps {
  dateVisible: boolean
  locationVisible: boolean
  dividerVisible: boolean
  verticalDividerWidth: number
  verticalDividerHeight: number
  horizontalDividerWidth: number
  horizontalDividerHeight: number
  horizontal: boolean
  alignmentClassName: string
  isMobile: boolean
}

export interface DateLocationProps extends DateLocationOwnProps, DateLocationStateProps {}

const mapState = ({state}: AppProps): DateLocationStateProps => {
  const componentConfig = getComponentConfig(state)

  return {
    dateVisible: isDateVisible(state),
    locationVisible: isLocationVisible(state),
    dividerVisible: isVerticalDividersVisible(state),
    verticalDividerWidth: getVerticalDividerWidth(componentConfig),
    verticalDividerHeight: getVerticalDividerHeight(componentConfig),
    horizontalDividerWidth: getHorizontalDividerWidth(componentConfig),
    horizontalDividerHeight: getHorizontalDividerHeight(componentConfig),
    horizontal: isHorizontalDateAndLocation(componentConfig),
    alignmentClassName: getAlignmentClassName(getAlignment(componentConfig)),
    isMobile: isMobile(state),
  }
}

const getMargin = ({state}: AppProps) => getDateAndLocationMarginBottom(getComponentConfig(state))

export const DateLocation = renderIfVisible(
  withMargin(connect<DateLocationOwnProps, DateLocationStateProps>(mapState)(DateLocationPresentation), getMargin),
  isDateAndLocationVisible,
)

import {getDescriptionMargin, isDescriptionVisible} from '../../../selectors/single-settings'
import {AppProps} from '../../app/interfaces'
import {renderIfVisible} from '../../hoc/render-if-visible'
import {withMargin} from '../../hoc/with-margin'
import {Description as DescriptionPresentation} from './description'

interface DescriptionOwnProps {
  event: wix.events.Event
  onImage: boolean
  isMobile: boolean
}

export interface DescriptionProps extends DescriptionOwnProps {}

const getMargin = ({state}: AppProps) => getDescriptionMargin(state)

export const Description = renderIfVisible(withMargin(DescriptionPresentation, getMargin), isDescriptionVisible)
